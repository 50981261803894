.ScheduleSection {
  padding: 2rem 0;
}

.ScheduleSection--Grid {
  background: var(--lightGrey);
  border-radius: 25px;
  padding: 32px;

  -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.3);
}

.ScheduleSection--Entry {
  display: flex;
}

@media screen and (max-width: 750px) {
  .ScheduleSection--Grid {
    padding: 16px;
  }
}

.ScheduleSection--TimeCol {
  width: 30%;
}

.ScheduleSection--Separator {
  border: 0.5px solid #979797;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.ScheduleSection--Title {
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  width: 100%;
  text-align: center;
  color: #000000;
  margin-bottom: 48px;
}
