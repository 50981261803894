.Stand {
  background-color: var(--primary-light);
  background-image: url("/images/background-pattern.png");
  background-position: bottom;
  background-size: cover;
}

.Stand--BackgroundImage {
  height: 50rem;
  box-shadow: 0px 200px 50px 20px rgba(0, 0, 0, 0.4) inset, 0px 10px 37px -3px rgba(0,0,0,0.83);
  background-color: white;
}

.Stand--Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Stand--Logo {
  max-width: 70vw;
  max-height: 300px;
  width: auto;
  height: 100px;
}

.Stand--BackButton {
  color: #ffa343;
  position: relative;
  display: flex;
  align-items: center;
  font-weight: bolder;
  font-size: large;
  text-decoration: none;
  letter-spacing: 0.1em;
}

.Stand--BackButton svg {
  transition: transform 0.2s ease-out;
  transform: translateX(0);
  height: 1.2em;
}

.Stand--BackButton:hover svg,
.Stand--BackButton:focus svg {
  transform: translateX(-0.25em);
}

.Stand--Content {
  background: white;
  margin-top: 15rem;
  padding: 4rem 4rem;
  border-radius: 25px;
}

@media screen and (max-width: 750px) {
  .Stand--Content {
    padding: 4rem 2rem;
  }
}

.Stand--Meta {
  font-size: 1rem;
  margin: 0 0 2rem 0;
  text-align: center;
}

.Stand--Meta > * + * {
  margin-left: 0.75em;
}

.Stand--Title {
  font-size: xx-large;
  text-align: left;
}

.Stand--InnerContent {
  padding: 0;
}

@media screen and (max-width: 750px) {
  .Stand--InnerContent {
    padding: 0;
  }
}

.Stand--Pagination {
  margin: 4rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Stand--Pagination--Link {
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
}

.Stand--Pagination--Link.next {
  margin-left: auto;
}

.Stand--ModalButtons {
  display: flex;
  flex-direction: row;
  margin-top: 36px;
}
