.EventSection {
  padding: 2rem 2rem;
}

.EventSection--Grid {
  background: var(--lightGrey);
  border-radius: 25px;
  padding: 32px;
}

.EventSection--Entry {
  display: flex;
}

@media screen and (max-width: 750px) {
  .EventSection--Grid {
    padding: 16px;
  }
}

.EventSection--TimeCol {
  width: 30%;
}

.EventSection--Separator {
  border: 0.5px solid #979797;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}

.EventSection--Title {
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  width: 100%;
  text-align: center;
  color: #000000;
  margin-bottom: 48px;
}

.EventSection--Content {
  text-align: center;
}

.EventSection--Activities-Container {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

.EventSection--Activity {
  flex: 1 1 0px;
  width: 50%;
  margin-left: 16px;
  margin-right: 16px;
}

.EventSection--Activity-Image-Container {
  background-color: rgb(251, 197, 129);
  flex: 1 1 0px;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 25px;

  -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.3);
}

.EventSection--Activity-Image {
  width: 200px;
  height: 200px;
  padding: 16px;
  /*filter: invert() saturate(1.5);*/
  /*filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(264deg) brightness(103%) contrast(101%);*/
}
