.Content {
  white-space: pre-line;
}

.Content--Image {
  max-width: 100%;
  height: auto;
}

.Content--Iframe {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.Content--Iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
