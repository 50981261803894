.Footer {
  padding: 3rem 0;
  background: var(--lightGrey);
  -webkit-box-shadow: 0px -5px 37px -3px rgba(0,0,0,0.83);
  box-shadow: 0px -5px 37px -3px rgba(0,0,0,0.83);
}

.Footer--Logo {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 10rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
}

.Footer--Text-Container {
  margin-bottom: 16px;
}

.Footer--Text {
  text-align: center;
  margin: 0 8rem;
  font-size: 14px;
  line-height: 24px;
}

.Footer--SocialMediaIcon {
  margin-right: 8px;
  margin-bottom: 32px;
}
