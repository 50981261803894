.HomePageHeader {
  color: white;
  padding: 15rem 0;
  display: flex;
  justify-content: center;
  height: 100vh;
  text-align: center;
  -webkit-box-shadow: 0px 5px 37px -3px rgba(0,0,0,0.83);
  box-shadow: 0px 5px 37px -3px rgba(0,0,0,0.83);
}

.HomePageHeader-large {
  padding: 20rem 0;
}

.HomePageHeader--Title {
  font-size: 8rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
  font-family: "Good Times Rg";
}

.HomePageHeader--Logo {
  width: 300px !important;
  height: 300px !important;
  margin: auto;
}

.HomePageHeader--Subtitle {
  font-weight: 500;
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 32px;
}

.HomePageHeader--BackgroundImage {
  background-position: top!important;
}

.HomePageHeader--arrow{
  margin-top: 64px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.HomePageHeader--arrow span{
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #ffa343;
  border-right: 5px solid #ffa343;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.HomePageHeader--arrow span:nth-child(2){
  animation-delay: -0.2s;
}

.HomePageHeader--arrow span:nth-child(3){
  animation-delay: -0.4s;
}

.HomePageHeader--helper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  margin-bottom: -50px;
}

.HomePageHeader--LastEdition {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgb(250, 158, 28);
  cursor: pointer;
  font-family: "Good Times Rg";
  font-size: 1em;
  text-decoration: none;
  padding: 1.5rem;
  transition: all 0.5s;
  border: 3px;
  border-style: solid;
  border-radius: 10px;
  width: auto;
  position: relative;
}

.HomePageHeader--LastEdition::before {
  content: "\2039";
  font-size: 35px;
  position: absolute;
  top: 0;
  right: 145px;
  bottom: 100%;
  opacity: 0;
  transform: translate(-50%, -50%);
}

.HomePageHeader--LastEdition:hover {
  background: rgb(249, 129, 32);
  transition: all 0.5s;
  border-radius: 10px;
  padding: 1.5rem 1.5rem 1.5rem 3.5rem;
}

.HomePageHeader--LastEdition:hover::before {
  opacity: 1;
  transition: all 0.5s;
}

@keyframes animate {
  0%{
    opacity: 0;
    transform: rotate(45deg) translate(-20px,-20px);
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
    transform: rotate(45deg) translate(20px,20px);
  }
}
