.ModalButton-container {
    display: flex;
    flex-direction: column;
    background: #F7F7F7;
    border-radius: 25px;
    padding: 16px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 32px;
    width: 108px;
    height: 108px;
}

.ModalButton-label {
    text-align: center;
    color: #000000;
}

.ModalButton-icon {
    color: #000000;
    font-size: 28px;
}
