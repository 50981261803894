.Home {
}

.Home--Section {
    background-image: url("/images/background-pattern.png");
    background-position: bottom;
    background-size: cover;
}

.Home--Section h1:after {
    height: 5px;
    display: block;
    width: 100px;
    margin: 4px auto;
    background: var(--primary);
    content: '';
}
