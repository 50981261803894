.LinksModal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 25px;
    border: none;
    outline: none;
    background-color: white;
    padding: 38px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50vw;
    height: 70vh;
    overflow-y: scroll;
}


@media screen and (max-width: 750px) {
    .LinksModal-container {
        padding: 16px;
        width: 95vw;
    }
}

.LinksModal-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.LinksModal-close-btn {
    background: #D8D8D866;
    border-radius: 50px;
    color: #A0A0A0;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 42px;
    cursor: pointer;
}

.LinksModal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 36px;
    color: #101C2A;
    margin-bottom: 32px;
}

.LinksModal-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.LinksModal-label {
    font-weight: bold;
    color: #101C2A;
    margin-right: 6px;
    line-height: 28px;
    margin-top: 12px;
}

.LinksModal-value {
    color: #101C2A;
    line-height: 28px;
}

.LinksModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #101C2A;
}
