.Logo {
  display: flex;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 3.24rem;
  width: 3.5rem;
  align-items: center;
  justify-content: center;
}
