.StandCard {
  text-decoration: none;
  margin: 2rem;/*
  background: white;
  color: inherit;
  border: 1px solid var(--lightGrey);
  border-radius: var(--borderRadius);
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);*/
  transition: all 0.15s ease-in-out;
  overflow: hidden;
}

.StandCard:hover,
.StandCard:focus {
  transform: translateY(-1px);
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.15);
}

.StandCard--Image {
  width: 100%;
  height: 15rem;
  display: flex;
  align-items: center;
}

.StandCard--Logo {
  height: auto;
  width: 100%;
}

.StandCard--Stand {
  height: auto;
  width: 100%;
}

.StandCard--Title {
  margin: 0;
  text-align: center;
}

.StandCard--Content {
  margin: 2rem 0 0 0;
  padding: 0 2.5rem 2.5rem;
}

.StandCard--Category {
  font-size: 1rem;
  text-align: right;
}
