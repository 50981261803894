.StandSection {
  padding: 2rem 0;
  /*background: rgb(251, 197, 129);*/
  color: white;
  border-radius: 25px;
  margin: 6rem -10%;
}
.StandSection .StandCard {
  width: calc(33.33% - 4rem);
}

.StandSection--Grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
}

@supports (display: grid) {
  .StandSection--Grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
  }

  .StandSection--Grid-Gold {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-column-gap: 1rem;
  }

  .StandSection--Grid-Other {
    display: grid;
    grid-template-columns: repeat(4, 1fr) !important;
    grid-column-gap: 1rem;
  }

  .StandSection .StandCard {
    width: auto;
    margin: 0 0 1rem 0;
  }
}

.StandSection--Title {
  font-weight: bold;
  font-size: 48px;
  line-height: 65px;
  width: 100%;
  text-align: center;
  /*color: #ffffff;*/
  color: #000000;
  margin-bottom: 48px;
}

@media screen and (max-width: 750px) {
  .StandSection .StandCard {
    width: 100%;
    margin: 0 0 4rem 0;
    display: block;
  }

  @supports (display: grid) {
    .StandSection--Grid {
      display: block;
      grid-template-columns: 1;
      grid-gap: 0;
    }

    .StandSection .StandCard {
      margin: 0 0 4rem 0;
    }
  }
}
