.VideoPlayer-Open {
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 16px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 1);
    border-radius: 5px;
    border: 5px solid #ffa343;
    border-bottom: 0;
    z-index: 1000;
}

.VideoPlayer-Closed {
     position: fixed;
     bottom: -360px;
     right: 0;
     margin-right: 16px;
     box-shadow: 0 2px 15px rgba(0, 0, 0, 1);
     border-radius: 5px;
     border: 5px solid #ffa343;
     border-bottom: 0;
     z-index: 1000;
 }

.VideoPlayer--ToggleButton {
    position: absolute;
    top: -30px;
    right: -5px;
    padding: 2px 16px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 1);
    border-radius: 5px 5px 0 0;
    background: #ffa343;
    border-bottom: 0;
    z-index: 999;
    cursor: pointer;
    text-align: center;
    color: white;
}
