.Nav {
  background: #ffffffee;
  position: sticky;
  top: 0;
  z-index: 1;
}

.Nav--Container {
  display: flex;
  align-items: center;
  height: 6rem;
}

.Nav .Logo {
  margin-right: 3rem;
}
