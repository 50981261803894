@font-face {
    font-family: 'Good Times Rg';
    src: url('GoodTimesRg-Regular.eot');
    src: url('GoodTimesRg-Regular.eot?#iefix') format('embedded-opentype'),
        url('GoodTimesRg-Regular.woff2') format('woff2'),
        url('GoodTimesRg-Regular.woff') format('woff'),
        url('GoodTimesRg-Regular.ttf') format('truetype'),
        url('GoodTimesRg-Regular.svg#GoodTimesRg-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

